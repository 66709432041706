import React from "react";
import { useNavigate } from "react-router";

const UserCard = ({ user }) => {
  const navigate = useNavigate();
  const userHandler = (e, id) => {
    console.log(e.target, id);
    navigate(`users/${id}`);
  };
  return (
    <li
      className="list-item"
      style={{
        cursor: "pointer",
        borderRadius: "4px",
        padding: "25px",
        margin: "5px",
      }}
      onClick={(e) => userHandler(e, user.id)}
    >
      {user.name} : {user.company.name}
    </li>
  );
};

export default UserCard;
