import React, { useEffect, useState } from "react";
import UserCard from "../common/UserCard";
import background from "../images/background.jpg";

const Home = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const response = await fetch("https://jsonplaceholder.typicode.com/users");
    const data = await response.json();
    setUsers(data);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div
        className="homePage"
        style={{
          position: "relative",
          overflow: "hidden",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="pageTitle">
          <h2>Users</h2>
        </div>
        <div className="searchBar" style={{ width: "100%" }}>
          <input
            type="text"
            onChange={handleSearchChange}
            placeholder="Search users..."
            style={{ borderRadius: "4px", width: "50%", marginBottom: "30px" }}
          />
        </div>
        <ul className="list">
          {filteredUsers.map((user) => (
            <UserCard key={user.id} user={user} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Home;
