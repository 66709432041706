import React from "react";

const About = () => {
  return <div className="pageTitle">
    <h2>About</h2>
    <p>This is a test React app created by Antony Roussos</p>
    </div>;
};

export default About;
